// Fonts
@import url('https://fonts.googleapis.com/css2?family=Bellefair&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

html, body{
    height: 100%;
}

body{
	font-size: 16px;
	font-family: 'Roboto', sans-serif!important;
}

b, strong {
    font-weight: 600!important;
}

.enter{
	background-color: rgba(255,255,255, .0)!important;
}

.mini-logo{
	width: 150px;
	margin-bottom: 14px;
}

.btn-ouv{
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
}

.btn-ouv-left{
    border-top-left-radius: 0;
    border-bottom-left-radius: 3px;
    border-left: 0;
    border-bottom: 0;
	background: #fff;
}

.btn-ouv-right{
    border-top-right-radius: 0;
    border-bottom-right-radius: 3px;
    border-right: 0;
    border-bottom: 0;
	background: #fff;
}

.btn-group-enter{

}

.btn-one {
	color: #FFF;
	transition: all 0.3s;
	position: relative;
}
.btn-one span {
	transition: all 0.3s;
}
.btn-one::before {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	opacity: 0;
	transition: all 0.3s;
	border-top-width: 1px;
	border-bottom-width: 1px;
	border-top-style: solid;
	border-bottom-style: solid;
	border-top-color: rgba(255,255,255,0.5);
	border-bottom-color: rgba(255,255,255,0.5);
	transform: scale(0.1, 1);
}
.btn-one:hover span {
	letter-spacing: 2px;
}
.btn-one:hover::before {
	opacity: 1;	
	transform: scale(1, 1);	
}
.btn-one::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	transition: all 0.3s;
	background-color: rgba(255,255,255,0.1);
}
.btn-one:hover::after {
	opacity: 0;	
	transform: scale(0.1, 1);
}

#myVideoOuv {
	position: fixed;
	right: 0;
	bottom: 0;
	min-width: 100%;
	min-height: 100%;
}

.video-container {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%; 
	overflow: hidden;
	object-fit: fill;
}
.video-container video {
	/* Make video to at least 100% wide and tall */
	min-width: 100%; 
	min-height: 100%; 
    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
	width: auto;
	height: auto;

	  /* Center the video */
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}

.btn-dark{
	background-color: #000;
	border: solid 1px #000;
}

.btn-dark:hover{
	background-color: #fff;
	color: #000;
	border: solid 1px #000;
}

.card-hg{
    max-height: 80vh;
    overflow-y: auto;
}


// hamburger

.btn-hamb{
	color: #333;
	position: absolute;
	right: 30px;
	top: 10px;
	z-index: 1000;
	background: #fff;
}

.btn-mini-nav{
	color: #fff;
    position: absolute;
    left: 15px;
    top: 10px;
    z-index: 10;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    background-color: #000000;
    padding: 4px 10px;
	border: solid 1px #000;
}
.btn-mini-nav:hover{
	background: #fff;
	color: #000;
	
}


.btn-hamb-link{
	display:block;
	width: 100%;
	text-align: center;
	color: #000;
	font-size:24px;
	padding-top:12px;
}

.img-holder{
	text-align: center;
}

.fw-500{
	font-weight: 500;
}

.btn-lg{
	font-size: 1.0rem;
}

.border-l{
	border-left: solid 1px #ddd;
	
}

.lista{
	list-style:none;
	padding:0;
	margin: 0;
}

.lista li{
	list-style:none;
	padding: 8px 15px;
	font-size:14px;
}

.lista li:nth-child(odd){
	background-color: #f4f4f4;
}

.lista li:hover{
	background-color: #f0f0f0;
}

.lista li a{
	color: #333;
	font-size: 15px;
	font-style: none;
	text-decoration: none;
}

b, strong {
	font-weight: 500;
}

.container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1024px;
}

.crte{
	height: 200%;
    border-left: solid 20px #000;
    position: fixed;
    border-right: solid 20px #000;
    left: 0;
    bottom: -800px;
    transform: rotate(315deg);
    display: block;
    padding: 20px;
}


.crte-gore{
	height: 200%;
    border-left: solid 20px #000;
    position: fixed;
    right: 0;
    bottom: -200px;
    transform: rotate(315deg);
    display: block;
    padding: 20px;
}

/**/

.lines{
	overflow: hidden; 
}
.crta-1-up{
	width: 500px;
    background: #000;
    position: absolute;
    text-align: center;
    height: 40px;
    letter-spacing: 1px;
    color: #f0f0f0;
    top: 125px;
    right: -150px;
    /* left: 50px; */
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
	animation: move-crta-up ease 3s;
}


.crta-1-down{
	width: 500px;
    background: #000;
    position: absolute;
    text-align: center;
    height: 40px;
    letter-spacing: 1px;
    color: #f0f0f0;
    bottom: 125px;
    right: auto;
    left: -100px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
	animation: move-crta-down ease 3s;
}

.main_menu_list{
	margin-left: -150px;
}

.btn-white{
	background: #fff;
	color: #000;
	border-color: #000;
	font-weight: 500;
}

.btn-white:hover{
	background: #000;
	color: #fff;
	border-color: #fff;
}

@keyframes move-crta-up {
	from{
		top: -200px;
	}
  	to {
    	top: 125px;  /* final resting position */
  	}
}

@keyframes move-crta-down {
	from{
		bottom: -200px;
	}
  	to {
    	bottom: 125px  /* final resting position */
  	}
}

.btn-hamb{
	display: none;
}

#map {
	width: 100%;
	height: 300px;
	background: #efefef;
}

.cookie{
	position: fixed;
	padding: 8px 12px;
	background: #fff;
	bottom: 10px;
	font-size: 12px;
	right: 10px;
	border: solid 1px #000;
	visibility: visible;
}

.cookie a.btn{
	display: block;
}

.footer{
	position: absolute;
	text-align: center;
	color: #fff;
	font-size: 12px;
	bottom: 10px;
	left: 10px;
	right: 10px;
}

.navigation-text{

}

.legal-logo{
	font-weight: 500;
    font-size: 10px;
    color: #333;
    text-align: center;
}

.legal-logo  img{
	width: 80%;
}

/*



*/

#spin_wrap {
  margin: 0;
  background: #fff;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
	position: absolute;
	z-index: 100;
}

.spinning-loader {
  width: 150px;
  height: 150px;
  border: 5px solid #eee;
  border-left-color: #000;
  border-radius: 50%;

  animation-name: rotate-s-loader;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-timing-function: linear;
  position: relative;
}

#spin_wrap img{
  position: absolute;
	
}

@keyframes rotate-s-loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (min-width: 1281px) {
	.navigation-text{
		width:540px;
	}
	
	.navigation-text-1{
		position: absolute;
		bottom: 20px;
		left: 400px;
	}
}
@media only screen and (max-width: 600px) {
	.container{
		padding-right: var(--bs-gutter-x, 1.75rem);
		padding-left: var(--bs-gutter-x, 1.75rem);
	}
	.btn-hamb{
		display: block;
		right: 10px;
	}
	
	.btn-one {
		width: 100%!important;
		margin-left: 0!important;
	}
	
	.mar-0{
		margin-left: 0!important;
	}
	
	.crte-gore{
		height: 200%;
		border-left: solid 15px #000;
		position: fixed;
		border-right: solid 15px #000;
		right: 0;
		bottom: -100px;
		transform: rotate(315deg);
		display: block;
		padding: 10px;
	}
	
	.crte{
		display: none;
	}
	
	.crta-1-up{
		width: 500px;
		background: #000;
		position: absolute;
		text-align: center;
		height: 14px;
		letter-spacing: 1px;
		color: #f0f0f0;
		top: 25px;
		right: -180px;
	}
	.crta-1-down{
		height: 14px;
		bottom: 25px;
    	left: -160px;
	}
	
	.logo-izbornik{
		width:200px!important;
	}
	
	.cookie{
		left: 10px;
	}
	
	.main_menu_list{
		margin-left: 0;
	}
	
	.btn-white{
		padding: 0.5rem 1rem!important;
	}
	
	.navigation-text{
		
		display: none;
	}
}